import { FlowPhase, PhaseSlug, ReleaseWindow } from '@/types/codegen-federation'

export type FlowPhaseForStatusCheck = Pick<FlowPhase, 'status' | 'phaseSlugEnum' | 'releaseWindows'>

export function isSellingTickets(flowPhases: FlowPhaseForStatusCheck[]) {
  return isInPresale(flowPhases) || inTheaters(flowPhases)
}

export function isInPresale(flowPhases: FlowPhaseForStatusCheck[]) {
  return (
    flowPhases?.some((flowPhase) => flowPhase?.status === 'current' && flowPhase.phaseSlugEnum === 'pre_sale') ?? false
  )
}

export function inTheaters(flowPhases: FlowPhaseForStatusCheck[]) {
  return (
    flowPhases?.some((flowPhase) => flowPhase?.status === 'current' && flowPhase.phaseSlugEnum === 'theatrical') ??
    false
  )
}

export function isStreaming(flowPhases: FlowPhaseForStatusCheck[]) {
  return (
    flowPhases?.some((flowPhase) => {
      const isStreamingFree = flowPhase?.phaseSlugEnum === 'free_access' && flowPhase.status === 'current'
      const isStreamingInGuild = flowPhase?.phaseSlugEnum === 'guild_access' && flowPhase.status === 'current'
      return isStreamingFree || isStreamingInGuild
    }) ?? false
  )
}

type FlowPhaseForReleaseDateCheck = Pick<FlowPhase, 'phaseSlugEnum'> & {
  releaseWindows: Pick<ReleaseWindow, 'start'>[]
}

export function mapGuildAccessStart(flowPhases: FlowPhaseForReleaseDateCheck[]): Date | undefined {
  return mapPhaseReleaseWindowStart(flowPhases, 'guild_access')
}

export function mapFreeAccessStart(flowPhases: FlowPhaseForReleaseDateCheck[]): Date | undefined {
  return mapPhaseReleaseWindowStart(flowPhases, 'free_access')
}

function mapPhaseReleaseWindowStart(
  flowPhases: FlowPhaseForReleaseDateCheck[],
  phaseSlugEnum: PhaseSlug,
): Date | undefined {
  const guildAccessFlowPhase = flowPhases?.find((flowPhase) => flowPhase.phaseSlugEnum === phaseSlugEnum)
  if (
    !guildAccessFlowPhase ||
    !Array.isArray(guildAccessFlowPhase.releaseWindows) ||
    guildAccessFlowPhase.releaseWindows.length <= 0
  )
    return undefined

  return guildAccessFlowPhase.releaseWindows[0]?.start
}
