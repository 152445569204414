import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'next/navigation'
import LazyHydrate from 'react-lazy-hydration'
import { VideoModal } from '@/molecules/VideoModal'
import { Page } from '@/page'
import { PageDataContext, PageModel } from '@/services/RenderService'
import { AppPromoSection } from '../LandingView/AppPromoSection'
import { WatchBackground } from './WatchBackground'

export interface WatchLandingViewProps {
  preview: boolean
  pageDataContext: PageDataContext
  page: PageModel
}

export const WatchLandingView: React.FC<WatchLandingViewProps> = ({ pageDataContext, page }) => {
  const [showModal, setShowModal] = useState(false)
  const searchParams = useSearchParams()
  const welcomeUrl = 'https://content.angelstudios.com/watch/Angel_Welcome_Neal_Hook1_Outro1_16x9_V5_2024-5-11_CJ.mp4'

  useEffect(() => {
    if (searchParams.has('scene') && searchParams.get('scene') === 'neal-welcome-2024') {
      setShowModal(true)
    }
  }, [searchParams])

  const handleClose = () => {
    setShowModal(false)
  }

  return (
    <div className="relative overflow-hidden">
      <WatchBackground />
      <div className="pt-24 text-white">
        <section className="flex flex-col gap-y-12 md:pt-4">
          <Page page={page} pageDataContext={{ ...pageDataContext, contentItemConfig: { padding: true } }} />
        </section>

        <section className="relative w-full bg-core-gray-950">
          <LazyHydrate whenVisible>
            <AppPromoSection />
          </LazyHydrate>
        </section>
      </div>
      <VideoModal showModal={showModal} videoUrl={welcomeUrl} handleClose={handleClose} />
    </div>
  )
}
